import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  AutoPurchase,
  ThankBillet,
  ThankCard
} from "../components/AutoPurchase";

const routes = () => {
  return (
    <Switch>
      <Route path="/boleto" exact component={ThankBillet}></Route>
      <Route path="/cartao-credito" exact component={ThankCard}></Route>
      <Route path="/:indicationCode?" exact component={AutoPurchase}></Route>
      <Route component={AutoPurchase} />
    </Switch>
  );
};

export default routes;
