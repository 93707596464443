import * as yup from "yup";

const schemaClient = yup.object().shape({
  name: yup.string().required("O campo nome completo é obrigatório!"),
  document: yup
    .string()
    .min(14, "Documento é necessário ter 11 dígitos!")
    .min(11, "Documento é necessário ter 11 dígitos!")
    .required("O campo documento é obrigatório!"),
  phone: yup
    .string()
    .min(16, "Celular é necessário ter 11 dígitos!")
    .min(11, "Celular é necessário ter 11 dígitos!")
    .required("O campo telefone é obrigatório!"),
  birth: yup
    .string()
    .min(10, "Data de nascimento é necessário ter 8 dígitos!")
    .required("O campo data de nascimento é obrigatório!"),
  dueDate: yup.string().required("O vencimento é obrigatório!"),
  paymentForm: yup.string().required("A forma de pagamento é obrigatória!"),
  paymentType: yup
    .string()
    .required("Escolha o o período de pagamento, mensal ou anual"),
  terms: yup
    .boolean()
    .oneOf([true], "Necessário ler e aceitar os termos de aceite!")
});

const schemaPayment = yup.object().shape({
  card_number: yup.string().required("O campo numero do cartão é obrigatório!"),
  name_card: yup
    .string()
    .required("O campo nome impresso no cartão é obrigatório!"),
  monthYear: yup
    .string()
    .required("O campo mês e ano de vencimento é obrigatório!")
    .min(4, "O campo mês e ano é necessário ter 4 dígitos!"),
  code: yup.string().required("O campo código do cartão é obrigatório!")
});

export { schemaClient, schemaPayment };
