function clone(obj) {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

const isCelular = cel => {
  if (!cel) {
    return null;
  }
  let ncel = cel.toString();
  ncel = ncel.replace(/\D/g, "");
  const r = ncel.match(
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[6-9])\d{3})-?(\d{4}))$/
  );
  if (!r || !r.length || r.length < 1) {
    return false;
  }
  return true;
};

const formatNumber = (number = 0) => {
  return `R$ ${number.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

const getAge = dateString => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export { clone, isCelular, formatNumber, getAge };
