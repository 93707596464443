import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Boleto from "boleto.js";
import Toastify from "toastify-js";
import moment from "moment";
import { FiCornerRightDown } from "react-icons/fi";

function ThankBillet({ location = {} }) {
  const [billet, setBillet] = useState({});
  const [data, setData] = useState({});
  const [defautProps] = useState({
    center: {
      lat: -15.5985037,
      lng: -56.0886187
    },
    zoom: 16
  });

  useEffect(() => {
    if (location?.state?.barcode) {
      const _billet = new Boleto(location?.state?.barcode);
      setBillet(_billet);
      setData(location?.state);
    }
  }, []);

  const copyToClipboard = () => {
    var textArea = document.createElement("textarea");
    textArea.value = data.barcode;
    document.body.appendChild(textArea);
    textArea.select();
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    if (msg === "successful") {
      Toastify({
        text: "Código de barras copiado com sucesso!",
        duration: 5000,
        backgroundColor: "#345EA8",
        className: "info"
      }).showToast();
    }
    document.body.removeChild(textArea);
  };

  const openNewTab = url => {
    let win = window.open(url, "_blank");
    win.focus();
  };

  return (
    <>
      <div
        className="container-contact100"
        style={{ padding: 0, background: "#ffff" }}
      >
        <div className="wrap-contact100 final-container">
          <span className="contact100-form-title p-b-5">
            Parabéns pela Adesão
          </span>

          <span
            className="contact100-form-sub-title p-b-0"
            style={{ fontSize: `${18}px` }}
          >
            Você agora faz parte do nosso programa de benefícios BR5! Agora
            falta muito pouco para você conseguir proteger você e toda sua
            familia.
          </span>
        </div>

        <div className="w-full">
          <div className="wrap-contact100 first-section">
            <div className="wrap-contact100 video-section">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/0MzuAi6SG_8"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="m-t-40"></div>
            <div className="text-center">
              <span className="text-download-app">BAIXE NOSSO APLICATIVO</span>
              <FiCornerRightDown
                className="m-t-1 m-l-5"
                style={{ position: "absolute" }}
                size={20}
                color={"#ffff"}
              />
            </div>

            <div
              style={{
                display: "block",
                margin: `${0} auto`,
                marginTop: `${1.3}rem`,
                textAlign: "center"
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=sou.br5"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="image-android"
                  className="image-phone"
                  src={
                    process.env.PUBLIC_URL +
                    "/img/public_img_download-google.png"
                  }
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://apps.apple.com/br/app/br5-benef%C3%ADcios/id1496268364"
                target="_blank"
              >
                <img
                  alt="image-ios"
                  className="image-phone"
                  src={
                    process.env.PUBLIC_URL + "/img/public_img_download-ios.png"
                  }
                />
              </a>
            </div>
          </div>
          {data.hasOwnProperty("orderId") && (
            <>
              <div className="wrap-contact100 third-section boleto-section">
                <div className="row">
                  <div className="col-md-6 col-info-billet">
                    <div className="info-billet">
                      <span className="title-info-billet p-b-5">Pedido</span>{" "}
                      <br />
                      <span>{data.orderId}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-info-billet">
                    <div className="info-billet">
                      <span className="title-info-billet">Valor</span> <br />
                      <span>{billet.prettyAmount()}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-info-billet">
                    <div className="info-billet">
                      <span className="title-info-billet">Vencimento</span>{" "}
                      <br />
                      <span>
                        {moment(billet.expirationDate(), "DD-MM-YYYY").format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row m-t-8 col-info-billet">
                  <div className="col-md-12">
                    <div
                      className="info-billet"
                      style={{ textAlign: "center" }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: new Boleto(data.barcode).toSVG()
                        }}
                      ></div>
                      <span>{billet.prettyNumber()}</span>
                    </div>
                  </div>
                </div>
                <div className="group-button m-t-15">
                  <a
                    className="link-boleto"
                    style={{ width: `${100}%` }}
                    href="javascript:void(0);"
                    onClick={() => copyToClipboard()}
                  >
                    <div className="button-billet">
                      <span className="text-instalacao">
                        Copiar Código de Barras
                      </span>
                    </div>
                  </a>
                  <a
                    className="link-boleto"
                    style={{ width: `${100}%` }}
                    href={data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="button-billet">
                      <span className="text-instalacao">Abrir Boleto</span>
                    </div>
                  </a>
                </div>
              </div>
              <div
                className="wrap-contact100 third-section"
                style={{ backgroundColor: "#6eb3bd" }}
              >
                <div>
                  <div className="description-tutorial">
                    <span className="contact100-form-title p-b-0 text-instalacao">
                      Saiba como instalar e ultilizar noso aplicativo
                    </span>{" "}
                    <br />
                    <span className="contact100-form-sub-title p-b-0 text-instalacao">
                      Simples e fácil de instalar você vai poder ultilizar de
                      todos nossos benefícios de forma rapida e prática.
                    </span>
                  </div>
                  <div className="instalacao m-t-20">
                    <div
                      onClick={() =>
                        openNewTab(
                          process.env.PUBLIC_URL + "/pdf/manual-app.pdf"
                        )
                      }
                      className="button-tutorial p-t-10"
                      style={{
                        backgroundColor: "#325f98",
                        paddingTop: `${2.5}rem`
                      }}
                    >
                      <span className="text-tutorial-app">
                        MANUAL DO USUÁRIO
                      </span>
                    </div>
                    {/* <div
                      onClick={() =>
                        openNewTab(
                          "https://www.youtube.com/watch?v=WeqqvREAkBA"
                        )
                      }
                      className="button-tutorial"
                      style={{
                        backgroundColor: "#325f98",
                        paddingTop: `${2.5}rem`
                      }}
                    >
                      <span className="text-tutorial-app">
                        COMO GANHAR 30 REAIS
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="w-full" style={{ margin: `${0} auto` }}>
          {/* <div style={{ width: "100%", height: "25rem", position: "relative" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyBV0xEkqPh6EiHSXz_GfSQ0alfCiMRG9JU"
              }}
              defaultCenter={defautProps.center}
              defaultZoom={defautProps.zoom}
              draggable={false}
            ></GoogleMapReact>
            <div className="box-localizacao">
              <div style={{ padding: `${2.5}rem` }}>
                <span
                  className="contact100-form-title p-b-0"
                  style={{
                    color: "#ffff",
                    fontSize: `${20}px`,
                    textAlign: "left"
                  }}
                >
                  Localização
                </span>
                <span
                  className="contact100-form-sub-title pt-3"
                  style={{
                    color: "#ffff",
                    fontSize: `${18}px`,
                    textAlign: "left"
                  }}
                >
                  <span className="m-b-5">
                    Av. Jão Gomes Sobrinho, 299 - Ed. Pies{" "}
                  </span>
                  <br />
                  <span>CEP: 78.088-800 | Cuiabá - MT </span>
                  <br />
                  <span>65.3054-8400 | 0800 006 8402 </span>
                  <br />

                  <a href="javascript:void(0);" style={{ color: "#3cabbc" }}>
                    contato@eceonomybrasil.com.br
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          {/* <div className="w-full">
            <div className="wrap-contact100 last-section">
              <div className="row text-center">
                <div className="col-md-3">
                  <span style={{ fontWeight: "bold" }}>EMPRESA</span>
                  <div className="m-t-10">
                    <img
                      width={140}
                      src={process.env.PUBLIC_URL + "/img/logo-sou.png"}
                      alt="img-logo"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <span style={{ fontWeight: "bold", marginBottom: `${1}rem` }}>
                    CENTRAL DE ATENDIMENTO
                  </span>{" "}
                  <div className="m-t-10">
                    <span
                      className="contact100-form-sub-title text-last-section p-b-5"
                      style={{ fontSize: `${14}px` }}
                    >
                      Fale conosco através do nosso canal de atendimento via
                      whatsapp de segunda a sexta-feira das 9 ás 18hrs.
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <span style={{ fontWeight: "bold" }}>ACOMPANHE-NOS</span>{" "}
                  <div className="m-t-10">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/br5beneficiosoficial"
                    >
                      <img
                        style={{
                          padding: `${5}px`
                        }}
                        width={60}
                        height={60}
                        src={process.env.PUBLIC_URL + "/img/facebook.svg"}
                        alt="facebook"
                      />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.youtube.com/channel/UCL1Ho9beOYQRw1PiGzWdRjg"
                    >
                      <img
                        style={{
                          padding: `${5}px`
                        }}
                        width={60}
                        height={60}
                        src={process.env.PUBLIC_URL + "/img/youtube.svg"}
                        alt="youtube"
                      />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_bank"
                      href="https://www.instagram.com/br5beneficiosmt/"
                    >
                      <img
                        style={{
                          padding: `${5}px`
                        }}
                        width={60}
                        height={60}
                        src={process.env.PUBLIC_URL + "/img/instagram.svg"}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <span style={{ fontWeight: "bold" }}>SITE SEGURO</span>
                  <div className="m-t-10">
                    <span>
                      <FiLock /> AMBIENTE SEGURO
                    </span>
                  </div>
                  <div className="m-t-5">
                    <span>
                      <FiShield />
                      SSL CERTIFICADO
                    </span>
                  </div>
                </div>
              </div>

              <hr />
              <span
                className="contact100-form-sub-title text-last-section p-b-5"
                style={{ fontSize: `${17}px` }}
              >
                Aviso Legal
              </span>
              <span
                className="contact100-form-sub-title text-last-section p-b-5"
                style={{ fontSize: `${12}px` }}
              >
                As informações contidas neste site são publicadas exclusivamente
                para fins informativos e não podem ser consideradas como
                aconselhamento médico pessoal. O leitor deve, para qualquer
                questão relativa à sua saúde e bem-estar, consultar um
                profissional devidamente credenciado pelas autoridades de saúde.
                O editor deste conteúdo não é médico ou pratica a medicina a
                qualquer título, ou qualquer outra profissão terapêutica. Apenas
                expressa sua opinião baseado em dados e fatos apresentados por
                agentes da saúde, ou conteúdo informativo disponível ao público,
                considerados confiáveis na data de publicação. Posto que as
                opiniões nascem de julgamentos e estimativas, estão sujeitas a
                mudança. Elaborado por editores independentes da Vittanatus,
                este relatório é de uso exclusivo de seu destinatário. São
                estritamente proibidos, sem autorização por escrito do detentor
                dos direitos autorais, sob as penalidades previstas em lei, a
                comunicação ou a distribuição dos materiais incluídos neste
                site, bem como a reprodução total ou parcial, por qualquer meio
                ou processo, incluindo fotocópias e distribuição via computador.
                O Ministério da Saúde Adverte: não existem evidências
                científicas comprovadas de que estes alimentos previnam, trate
                ou cure doenças.
              </span>
              <hr />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ThankBillet;
