import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { FiLock, FiShield, FiAward } from "react-icons/fi";

window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;

  // 20 is an arbitrary number here, just to make you think if you need the prevScrollpos variable:
  if (currentScrollPos > 60) {
    // I am using 'display' instead of 'top':
    document.getElementById("nav-scroll").style.display = "none";
  } else {
    document.getElementById("nav-scroll").style.display = "initial";
  }
};

function App() {
  return (
    <BrowserRouter>
      {/* <Routes /> */}
      <div className="wrapper">
        <nav
          id="nav-scroll"
          className="navbar navbar-expand-md navbar-light navbar-alt bg-light fixed-top no-border"
        ></nav>

        <div id="main" className="main">
          <Routes />

          {/* <div className="yd_cta_sub">
            <div className="cta_sub">
              <div className="cta_sub_inner">
                <div className="container">
                  <div className="col-lg-12 offset-lg-12">
                    <h4>Autocompra BR5 Benefícios</h4>
                    <p style={{ color: "#fff", fontSize: "16px" }}>
                      Caso tenha dúvidas, ligue na nossa central de atendimento
                      via telefone
                    </p>
                    <h2>0800 006 8402</h2>
                    <p style={{ color: "#fff", fontSize: "16px" }}>
                      Atendimento de segunda a sexta das 08:00 às 18:00
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="w-full">
            <div className="wrap-contact100 last-section">
              <div className="dis-flex text-center row p-t-10 p-b-20">
                <div className="col-md-4">
                  <span>
                    <FiAward /> SITE SEGURO
                  </span>
                </div>
                <div className="col-md-4">
                  <span>
                    <FiLock /> AMBIENTE SEGURO
                  </span>
                </div>
                <div className="col-md-4">
                  <span>
                    <FiShield />
                    SSL CERTIFICADO
                  </span>
                </div>
              </div>

              <div className="row text-center p-t-45 p-b-20">
                <div className="col-md-6 divisor-logo">
                  <span style={{ fontWeight: "bold" }}>GRUPO</span>
                  <div>
                    <div className="row p-b-20 p-t-10">
                      <div className="col-md-12 text-center">
                        <img
                          className="m-r-15"
                          width={140}
                          src={
                            process.env.PUBLIC_URL + "/img/logo-grupo-sou.svg"
                          }
                          alt="img-logo"
                        />
                        <img
                          className="m-l-15"
                          width={140}
                          src={process.env.PUBLIC_URL + "/img/logo-br5.svg"}
                          alt="img-logo"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <img
                          className="m-r-15"
                          width={140}
                          src={
                            process.env.PUBLIC_URL + "/img/logo-top-seguros.svg"
                          }
                          alt="img-logo"
                        />
                        <img
                          className="m-l-15"
                          width={140}
                          src={
                            process.env.PUBLIC_URL +
                            "/img/logo-sou-mais-saude.svg"
                          }
                          alt="img-logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <span style={{ fontWeight: "bold", marginBottom: `${1}rem` }}>
                    CENTRAL DE ATENDIMENTO
                  </span>{" "}
                  <div className="m-t-10">
                    <span
                      className="contact100-form-sub-title text-last-section p-b-5"
                      style={{ fontSize: `${14}px` }}
                    >
                      Fale conosco através do nosso canal de atendimento via
                      whatsapp de segunda a sexta-feira das 9 ás 18hrs.
                    </span>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://wa.me/556599027754"
                    >
                      <img
                        style={{
                          padding: `${5}px`
                        }}
                        width={150}
                        src={
                          process.env.PUBLIC_URL + "/img/whatsapp-contact.svg"
                        }
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <span style={{ fontWeight: "bold" }}>ACOMPANHE-NOS</span>{" "}
                  <div className="m-t-10">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/br5beneficiosoficial"
                    >
                      <img
                        style={{
                          padding: `${5}px`
                        }}
                        width={60}
                        height={60}
                        src={process.env.PUBLIC_URL + "/img/facebook.svg"}
                        alt="facebook"
                      />
                    </a>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.youtube.com/channel/UCL1Ho9beOYQRw1PiGzWdRjg"
                    >
                      <img
                        style={{
                          padding: `${5}px`
                        }}
                        width={60}
                        height={60}
                        src={process.env.PUBLIC_URL + "/img/youtube.svg"}
                        alt="youtube"
                      />
                    </a>
                    <a
                      rel="noreferrer"
                      target="_bank"
                      href="https://www.instagram.com/br5beneficiosmt/"
                    >
                      <img
                        style={{
                          padding: `${5}px`
                        }}
                        width={60}
                        height={60}
                        src={process.env.PUBLIC_URL + "/img/instagram.svg"}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div> */}
                <div className="col-md-6 divisor-logo">
                  <span style={{ fontWeight: "bold" }}>
                    PRINCIPAIS PARCEIROS NACIONAIS
                  </span>
                  <div>
                    <div className="row p-b-20 p-t-10">
                      <div className="col-md-12 text-center">
                        <img
                          className="m-r-15"
                          width={140}
                          src={
                            process.env.PUBLIC_URL + "/img/logo-drogasil.svg"
                          }
                          alt="img-logo"
                        />
                        <img
                          className="m-l-15"
                          width={140}
                          src={
                            process.env.PUBLIC_URL + "/img/logo-sulamerica.svg"
                          }
                          alt="img-logo"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <img
                          className="m-r-15"
                          width={140}
                          src={process.env.PUBLIC_URL + "/img/logo-seguro.svg"}
                          alt="img-logo"
                        />
                        <img
                          className="m-l-15"
                          width={140}
                          src={
                            process.env.PUBLIC_URL + "/img/logo-droga-raia.svg"
                          }
                          alt="img-logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <span
                className="contact100-form-sub-title text-last-section p-b-5"
                style={{ fontSize: `${17}px` }}
              >
                Aviso Legal
              </span>
              <span
                className="contact100-form-sub-title text-last-section p-b-5"
                style={{ fontSize: `${12}px` }}
              >
                BR5 Benefícios trata-se única e exclusivamente de administração
                de um Cartão de Descontos, ou seja, NÃO SE TRATA DE UM PLANO DE
                SAÚDE, seguro saúde ou assistência de saúde, não havendo
                garantia e nem responsabilização pelos serviços oferecidos e
                pelo pagamento de despesas, nem assegurando desconto em todos os
                serviços obrigatoriamente garantidos pelo plano de saúde. Tudo
                que o cliente usar ou comprar será por ele pago ao parceiro,
                assegurando-se apenas os preços e descontos que constam na
                relação de empresas e serviços conveniados disponibilizados no
                site. www.br5beneficios.com.br. O atendimento será agendado em
                até 24 horas, mediante disponibilidade na clínica. Consulte a
                clínica parceira para as especialidades disponíveis e condições
                para marcar as consultas em nosso aplicativo
              </span>
              <hr />
            </div>
          </div>

          <div className="container">
            <a
              id="back-top"
              className="back-to-top js-scroll-trigger"
              href="#main"
            ></a>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
