import axios from "axios";
import { base } from "../config";
import Toastify from "toastify-js";

function response() {
  const instance = axios.create({ baseURL: base });
  instance.interceptors.response.use(
    response => {
      const { config } = response;
      // const { url } = config;

      return response;
    },
    error => {
      const { response = {} } = error;
      const { data } = response;
      if (data && data.error) {
        Toastify({
          text: data.error,
          duration: 5000,
          backgroundColor: "#345EA8",
          className: "info"
        }).showToast();
      }
      return Promise.reject(error);
    }
  );

  return instance;
}

export default response;
