import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { FiAlertCircle, FiChevronRight, FiInfo } from "react-icons/fi";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import { RotateSpinner } from "react-spinners-kit";
import api from "../../api";
import { schemaClient, schemaPayment } from "../../utils/formValidation";
import { getAge, isCelular } from "../../utils/helpers";

// eslint-disable-next-line react/prop-types
function AutoPurchase({ location = {}, match = {} }) {
  const ReactPixelOne = ReactPixel;
  const ReactPixelTwo = ReactPixel;

  const [form, setForm] = useState({
    terms: false
  });
  const [errors, setErrors] = useState([]);
  const [modalError, setModalError] = useState(false);
  const [modalOferta, setModalOferta] = useState(false);
  const [modalErroCampanha, setErroCampanha] = useState(false);
  const [modalBR5facil, setModalBR5facil] = useState(false);
  const [modalTermos, setModalTermos] = useState(false);
  const [modalCellPhoneConfirm, setModalCellPhoneConfirm] = useState(false);
  const [initiateCheckout, setInitiateCheckout] = useState(false);
  const [_queryString, setQueryString] = useState({});
  const [plan, setPlan] = useState({});
  const [ref, setRef] = useState("");
  const [track, setTrack] = useState({});
  const [loading, setLoading] = useState(false);
  const [verifyPhone, setVerifyPhone] = useState(false);
  const [contract, setContract] = useState("");
  const [blockUser, setBlockUser] = useState(false);
  const [prices, setPrices] = useState({
    total: 0,
    totalMonthly: 0,
    initialPayment: 0
  });

  let history = useHistory();

  const advancedMatching = {};
  const options = {
    autoConfig: true,
    debug: false
  };

  useEffect(() => {
    // ?utm_source=facebook&utm_campaing=1&utm_medium=1223&utm_content=content&plan_display=full&plan_type=123
    const url = location.search;
    const {
      utm_source = "",
      utm_campaing = "",
      utm_medium = "",
      utm_content = "",
      plan_display = "",
      plan_type = "",
      campaing = "",
      id = "",
      plan = ""
    } = queryString.parse(url);

    setQueryString({
      utm_source,
      utm_campaing,
      utm_medium,
      utm_content,
      plan_display,
      plan_type,
      campaing,
      id,
      plan
    });

    const paramsUlrs = {
      utm_source,
      utm_campaing,
      utm_medium,
      utm_content,
      plan_display,
      plan_type,
      campaing,
      id,
      plan
    };

    const { indicationCode = "" } = match?.params;
    const br5Recommendation = Cookies.get("br5Recommendation");
    if (br5Recommendation) getRecommendation(br5Recommendation);
    if (!br5Recommendation && indicationCode) getRecommendation(indicationCode);

    if (id && campaing) {
      getPersonByID(id, campaing);
    }

    getPlan(plan, paramsUlrs);
    setForm({
      dueDate: 10
    });
  }, []);

  useEffect(() => {
    verifyLead();
  }, [form.phone]);

  useEffect(() => {
    setForm({ ...form });
    if (form.paymentForm === "credit_card") onPress("paymentType", "cc_mensal");
    if (form.paymentForm === "billet") onPress("paymentType", "billet_mensal");
    if (form.paymentForm === "billet" && plan.identifier === "br5facil") {
      onPress("paymentType", "billet_anual");
    }
  }, [form.paymentForm]);

  const getPersonByID = async (id, campaing) => {
    setLoading(true);
    const data = {
      campaing,
      id
    };

    api
      .get(`/campaign/`, { params: data })
      .then(({ data: response }) => {
        const {
          nome = "",
          nascimento = "",
          celular = "",
          doc = "",
          enderecos = []
        } = response.data;

        let cep = "";

        if (enderecos.length) cep = enderecos[0].cep;

        setForm({
          name: nome,
          birth: nascimento,
          phone: celular,
          document: doc,
          dueDate: 10,
          cep
        });

        setModalOferta(true);
      })
      .catch(() => {
        setQueryString({
          campaing: "",
          id: ""
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPlan = async (plan = "", paramsUlrs = {}) => {
    let _plan = {};
    if (plan) {
      try {
        const { data } = await api.get(`params/plan/${plan}`, {
          params: paramsUlrs
        });
        _plan = data;
      } catch (error) {
        const { response = {} } = error;
        const { data } = response;

        if (data?.error.includes("a oferta não foi encontrada")) {
          setBlockUser(true);
          setErroCampanha(true);
          setTimeout(() => {
            setModalOferta(false);
          }, 500);
        }
        return;
      }
    } else {
      const { data } = await api.get("params/plan", {
        params: paramsUlrs
      });
      _plan = data;
    }
    setPlan(_plan.data);
    getContract(_plan.data.id, true);
    onPress("paymentForm", "credit_card");
    calculatePrice(_plan.data, "cc_mensal");
  };

  const getRecommendation = async code => {
    ReactPixelTwo.init("346829406392722", advancedMatching, options);

    const { data: _recommendation } = await api.get(
      `params/recommendation/${code}`
    );

    if (code) setRef(code);
    if (_recommendation?.data) setTrack(_recommendation.data);
    if (_recommendation?.data?.pixel) {
      const _pixel = _recommendation?.data?.pixel;
      ReactPixelOne.init(`${_pixel}`, advancedMatching, options);

      ReactPixelOne.pageView();
    }

    if (_recommendation?.data?.googleAnalytics) {
      const googleAnalytics = _recommendation?.data?.googleAnalytics;
      ReactGA.initialize([
        {
          trackingId: googleAnalytics,
          gaOptions: {
            name: "tracker1"
          }
        },
        {
          trackingId: "AW-726569893",
          gaOptions: {
            name: "tracker2"
          }
        }
      ]);

      ReactGA.pageview(window.location.pathname + window.location.search, [
        "tracker1",
        "tracker2"
      ]);
    }
  };

  const closeModalBR5facil = () => setModalBR5facil(false);
  const openModalBR5facil = () => setModalBR5facil(true);
  const closeModalError = () => setModalError(false);
  const openModalError = () => setModalError(true);
  const closeModalOferta = () => setModalOferta(false);
  const openModalOferta = () => setModalOferta(true);
  const closeErroCampanha = () => setErroCampanha(false);
  const openErroCampanha = () => setErroCampanha(true);

  const closeModalTermos = () => setModalTermos(false);
  const openModalTermos = () => setModalTermos(true);

  const onPress = (prop, value, isInput = false) => {
    if (prop === "birth") checkAgeBR5(value);
    if (prop === "paymentType") calculatePrice(plan, value);
    if (prop === "document") getContract(plan.id, true, value);

    if (setVerifyPhone && prop === "phone") setVerifyPhone(false);
    if (!initiateCheckout && isInput) {
      if (track?.pixel) ReactPixelOne.track("initiateCheckout");
      if (track?.googleAnalytics) {
        ReactGA.event(
          {
            category: "general",
            action: "begin_checkout"
          },
          ["tracker1", "tracker2"]
        );
      }
      setInitiateCheckout(true);
    }

    errors[prop] = "";
    form[prop] = value;
    setForm({ ...form });
    setErrors({ ...errors });
  };

  const getContract = async (planId, force = false, doc) => {
    //
    if (!/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/g.test(doc) && !force) return;

    const { data: contractData } = await api.get(`/params/contract/${planId}`, {
      params: { name: form.name || "CONTRATANTE", doc: doc || form.doc }
    });

    setContract(contractData.data);
  };

  const calculatePrice = (planObj, paymentType, paymentForm) => {
    if (!paymentForm) ({ paymentForm } = form);
    if (!paymentType) ({ paymentType } = form);

    const planData = planObj || plan;
    const { discounts } = planData;

    let discount;
    let mounthClycles;
    let valAnnually = planData.annually;
    let valMonthly = planData.monthly;
    let valInitial = planData.totalInitialPayment;
    let totalPayment = planData.totalInitialPayment;

    switch (paymentForm) {
      case "credit_card":
        if (paymentType === "cc_anual") {
          discount = discounts.creditCard.annually;
          mounthClycles = discounts.creditCard.monthsCycle;
        }

        if (paymentType === "cc_mensal") {
          discount = discounts.creditCard.monthly;
          mounthClycles = 1;
        }
        break;
      case "billet":
        if (paymentType === "billet_anual") {
          discount = discounts.billet.annually;
          mounthClycles = discounts.billet.monthsCycle;
        }

        if (paymentType === "billet_mensal") {
          discount = discounts.billet.monthly;
          mounthClycles = 1;
        }
        break;
      default:
        discount = 0;
        mounthClycles = 1;
        break;
    }

    let totalMonthly = mounthClycles * valMonthly;

    if (discount > 0) {
      const decimalPercentage = discount / 100;
      const val = totalMonthly * decimalPercentage;
      totalMonthly -= val;
    }

    setPrices({
      total: parseFloat((totalMonthly + valInitial).toFixed(2)),
      totalMonthly: parseFloat(totalMonthly.toFixed(2)),
      initialPayment: parseFloat(valInitial.toFixed(2)),
      totalInitialPayment: parseFloat(totalPayment.toFixed(2)),
      valMonthly: parseFloat(valMonthly.toFixed(2)),
      valAnnually: parseFloat(valAnnually.toFixed(2))
    });
  };

  const checkAgeBR5 = async (dateStr = "") => {
    if (!/([0-9]{2}\/[0-9]{2}\/[0-9]{4})/g.test(dateStr)) return;

    let identfier = "br5facil";

    const d = dateStr.split("/");
    let birth = `${d[2]}/${d[1]}/${d[0]}`;
    const age = getAge(birth);

    if (!moment(dateStr, "DD/MM/YYYY").isValid()) {
      alert("Por favor, preencha a data de nascimento corretamente.");
      return;
    }

    if (age <= 65) identfier = "familia";

    const { data: planData } = await api.get(`/params/plan/${identfier}`, {
      params: _queryString
    });

    setPlan(planData.data);

    // onPress("paymentForm", "credit_card");
    // onPress("paymentType", "cc_mensal");

    calculatePrice(planData.data, "cc_mensal");

    if (age > 65) {
      openModalBR5facil();

      switch (form.paymentForm) {
        case "credit_card":
          onPress("paymentType", "cc_mensal");

          break;
        case "billet":
          onPress("paymentType", "billet_anual");

          break;
      }
    } else if (form.paymentForm === "billet") {
      onPress("paymentType", "billet_mensal");
    }
  };

  const validateForm = async () => {
    const _errors = {};
    let _ok = true;

    await schemaClient
      .validate(form, { abortEarly: false })
      .catch(({ inner }) => {
        inner.map(({ path, message }) => {
          _ok = false;
          _errors[path] = message;
        });
      });

    if (form.paymentForm === "credit_card") {
      await schemaPayment
        .validate(form, { abortEarly: false })
        .catch(({ inner }) => {
          inner.map(({ path, message }) => {
            _ok = false;

            _errors[path] = message;
          });
        });
    }

    const _isCelular = await isCelular(form.phone);
    if (!_isCelular && !_errors["phone"]) {
      _errors["phone"] = "Insira um número de celular válido!";
      _ok = false;
    }

    if (!form.terms) {
      _errors["terms"] = "Necessário ler e aceitar os termos de aceite";
      _ok = false;
    }

    if (!moment(form.birth, "DD/MM/YYYY").isValid()) {
      _errors["birth"] = "Data de nascimento é invalida";
      _ok = false;
    }

    if (!(form?.birth && !form?.birth?.includes("_"))) {
      _errors["birth"] = "Data de nascimento é invalida";
      _ok = false;
    }

    setErrors({ ..._errors });
    return _ok;
  };

  const cellPhoneConfirm = async () => {
    try {
      setLoading(true);
      await api.post("/checkout/code/generate", { cellPhone: form.phone });

      setModalCellPhoneConfirm(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        message.error(error.response.data.error);
      } else {
        message.error(
          error.message || "Houve um erro ao realizar o envio do código."
        );
      }
    }
  };

  const submit = async () => {
    setLoading(true);
    const formResult = await validateForm();
    if (!formResult) {
      openModalError();
      setLoading(false);
      return;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });

    const d = form.birth.split("/");
    const _birth = `${d[2]}/${d[1]}/${d[0]}`;

    const data = {
      ip: await getIp(),
      name: form?.name,
      code: form?.code,
      phone: form?.phone.replace(/[()-\s]/g, ""),
      ...(form?.birth && !form?.birth?.includes("_")
        ? { birthdate: _birth }
        : ""),
      cep: form?.cep?.replace(/-/g, ""),
      plan: plan?.id,
      document: form?.document?.replace(/[.-]/g, ""),
      type: form?.paymentForm,
      monthlyDueDate: form?.dueDate?.toString(),
      cycle: form?.paymentType.split("_")[1],
      ...(form?.paymentForm === "credit_card"
        ? { card: await formatCreditCard() }
        : ""),
      ...(ref ? { recommendation: ref } : ""),
      ...(_queryString ? { params: _queryString } : "")
    };

    api
      .post("checkout", data)
      .then(({ data }) => {
        const { data: _data } = data;
        if (track.pixel) sendPurchase(_data);
        if (track.googleAnalytics) sendPurchaseGoogle(_data);
        if (_data.barcode) {
          history.push("/boleto", {
            ..._data
          });
        } else {
          history.push("/cartao-credito", { url: _data.url });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendPurchase = data => {
    ReactPixelOne.track("Purchase", {
      content_type: "product",
      content_ids: [data.orderId],
      ...(data.barcode
        ? { payment_type: "BILLET" }
        : { payment_type: "CREDIT_CARD" }),
      value: data.price,
      currency: "BRL"
    });

    ReactPixelTwo.track("Purchase", {
      content_type: "product",
      content_ids: [data.orderId],
      ...(data.barcode
        ? { payment_type: "BILLET" }
        : { payment_type: "CREDIT_CARD" }),
      value: data.price,
      currency: "BRL"
    });

    ReactPixelOne.trackCustom("payment_type", {
      content_name: data.barcode ? "BILLET" : "CREDIT_CARD"
    });

    ReactPixelTwo.trackCustom("payment_type", {
      content_name: data.barcode ? "BILLET" : "CREDIT_CARD"
    });
  };

  const sendPurchaseGoogle = data => {
    if (track?.googleAnalytics) {
      ReactGA.event(
        {
          category: "general",
          action: "purchase",
          value: data.price,
          currency: "BRL"
        },
        ["tracker1", "tracker2"]
      );
    }
  };

  const getIp = async () => {
    let dataIp = await axios.get("https://ipapi.co/json/");
    let ip = (dataIp && dataIp.data && dataIp.data.ip) || "";
    if (!dataIp) {
      dataIp = await axios.get("http://ipinfo.io/ip");
      ip = dataIp;
    }
    if (!dataIp) {
      dataIp = await axios.get("http://ip-api.com/json/");
      ip = (dataIp && dataIp.query) || "";
    }
    if (!dataIp) {
      dataIp = await axios.get("https://api.myip.com");
      ip = (dataIp && dataIp.ip) || "";
    }

    return ip;
  };

  const formatCreditCard = () => {
    return {
      number: form?.card_number,
      holderName: form?.name_card,
      expiryMonth: form?.monthYear?.split("/")[0],
      expiryYear: `20${form?.monthYear?.split("/")[1]}`,
      cvv: form?.cvv
    };
  };

  const verifyLead = async () => {
    // if (_queryString.campaing) return;
    if (form.phone) {
      const d = form?.birth?.split("/");
      let _birth = d ? `${d[2]}/${d[1]}/${d[0]}` : "";
      let phone = form?.phone.replace(/[()-\s]/g, "");
      let document = form?.document?.replace(/[.-]/g, "");

      const _errors = {};

      const _isCelular = await isCelular(form.phone);
      if (!_isCelular) {
        _errors["phone"] = "Insira um número de celular válido!";
        return setErrors({ ..._errors });
      }

      if (!verifyPhone && phone && document) {
        setVerifyPhone(true);
        const isPhone = await api.get(
          `/params/check-phone/${phone}/${document}`
        );
      }

      const data = {
        recommendation: ref,
        name: form?.name,
        phone,
        document,
        ...(form?.birth && !form?.birth?.includes("_")
          ? { birthdate: _birth }
          : ""),
        cep: form?.cep?.replace(/-/g, ""),
        ...(_queryString ? { params: _queryString } : "")
      };

      await api.post("/lead", data);
    }
  };

  const formatNumber = number => {
    if (number === undefined || number === null) number = 0;
    return ` R$ ${number.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };

  const calculatePercent = (value, percent) => {
    const v = (percent / 100) * value;
    const _v = value - v;
    return formatNumber(_v);
  };

  return (
    <>
      <Modal
        visible={modalErroCampanha}
        height="50%"
        width="80%"
        style={{ zIndex: "9999" }}
        effect="fadeInUp"
      >
        <div className="body-modal">
          <h4 className="title-offer">
            Ops... Tivemos um problema com seu link :(
          </h4>
          <h4 className="description-offer mt-5">
            Por favor, entre em contato com o nosso suporte!
          </h4>

          <h4
            className="description-offer mt-5"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            0800 006 8402
          </h4>
          <h4 className="description-offer mt-3">ou</h4>
          <h4
            className="description-offer mt-3"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            (65) 3054-8400
          </h4>
        </div>
      </Modal>
      {/* Modal cancelado 1 */}
      <Modal visible={modalOferta} height="100%" width="100%" effect="fadeInUp">
        <div className="body-modal">
          <h4 className="title-offer">
            Que bom que você decidiu voltar, {form?.name?.split(" ")[0]}!
          </h4>
          <h4 className="description-offer m-t-5">
            Foi liberado um{" "}
            <span style={{ color: "#bf2e3c", fontWeight: "bold" }}>
              DESCONTO
            </span>{" "}
            exclusivo para você de 100% na adesão
          </h4>
          <h4 className="title-offer m-t-5" style={{ color: "#bf2e3c" }}>
            <del>{formatNumber(plan.initialPayment)}</del>
          </h4>
          <h4 className="description-offer m-t-5">
            Você só paga <b>{formatNumber(prices?.valMonthly)}</b> mensalmente!
          </h4>
          {/* <h4 className="description-offer m-t-5">
            <span style={{ color: "#0E2739", fontWeight: "bold" }}>
              Proteção
            </span>{" "}
            para sua família em{" "}
            <span style={{ color: "#0E2739", fontWeight: "bold" }}>
              poucos cliques!
            </span>
          </h4> */}

          <div className="mt-2" style={{ textAlign: "center" }}>
            <h4 className="description-offer">
              Benefícios para você e toda sua família!
            </h4>

            <div className="row">
              <img
                className="animate__animated animate__pulse animate__delay-1s animate__infinite img-fluid img-consulta mt-5 mb-0"
                src={process.env.PUBLIC_URL + "/img/consultas-price.svg"}
                // width="550"
                alt="image"
              ></img>
            </div>

            <div style={{ display: "inline-block", justifyContent: "center" }}>
              <img
                className="image-consultas-price mt-5 m-b-30 pr-3 pl-3"
                src={process.env.PUBLIC_URL + "/img/beneficio1.svg"}
                width="160"
                alt="image"
              ></img>
              <img
                className="image-consultas-price mt-5 m-b-30 pr-3 pl-3"
                src={process.env.PUBLIC_URL + "/img/beneficio2.svg"}
                width="160"
                alt="image"
              ></img>
              <img
                className="image-consultas-price mt-5 m-b-30 pr-3 pl-3"
                src={process.env.PUBLIC_URL + "/img/beneficio3.svg"}
                width="160"
                alt="image"
              ></img>
              {_queryString.plan !== "br5facil" && (
                <>
                  <img
                    className="image-consultas-price mt-5 m-b-30 pr-3 pl-3"
                    src={process.env.PUBLIC_URL + "/img/beneficio4.svg"}
                    width="160"
                    alt="image"
                  ></img>
                  <img
                    className="image-consultas-price mt-5 m-b-30 pr-3 pl-3"
                    src={process.env.PUBLIC_URL + "/img/beneficio5.svg"}
                    width="160"
                    alt="image"
                  ></img>
                </>
              )}
            </div>
          </div>

          <div className="wrap-contact100-form-btn m-t-20 mb-5">
            <div className="contact100-form-bgbtn"></div>
            <button
              onClick={() => closeModalOferta()}
              className="contact100-form-btn"
            >
              <span className="text-button-offer">
                VAMOS LÁ!
                <i
                  className="fa fa-long-arrow-right m-l-7"
                  aria-hidden="true"
                ></i>
              </span>
            </button>
          </div>
        </div>
      </Modal>
      {/* Modal cancelado 2 */}
      <Modal visible={modalOferta} height="100%" width="100%" effect="fadeInUp">
        <div className="body-modal-2">
          <div className="section-name">
            <h4 className="title-offer-2">
              {form?.name?.split(" ")[0].toUpperCase()}
            </h4>
            <h4 className="title-offer-blur-2">
              {form?.name?.split(" ")[0].toUpperCase()}
            </h4>
          </div>

          <h4 className="subtitle-offer-2">
            Parabéns por sua decisão de voltar a
          </h4>

          <div className="col-12 col-md-11 col-lg-8 col-xl-7 box-offer-title-2">
            <div className="box-offer-2">
              <span className="subtitle-box-offer-2">
                proteger sua família!
              </span>
            </div>
          </div>

          <h4 className="announcement-benefits mt-5">
            São 5 grandes benefícios:
          </h4>

          <div className="row-benefits row justify-content-md-center">
            <div className="col-margin col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <div className="square-price">
                <div className="box-square">
                  <div>
                    <p className="title-square">
                      DESCONTOS <br /> EM SAÚDE
                    </p>
                  </div>
                  <div>
                    <p className="subtitle-square">Clínigo geral</p>
                  </div>
                  <div>
                    <p className="price-annuncement-square">a partir de</p>
                  </div>
                  <div>
                    <span className="type-price-2">R$</span>
                    <span className="price">20</span>
                    <span className="cents">,00</span>
                  </div>
                </div>
              </div>
              <div className="rectangle-section">
                <img
                  className="img-rectangle"
                  src={process.env.PUBLIC_URL + "/img/rectangle-square.svg"}
                  alt="image"
                />
                <img
                  className="icon-rectangle"
                  src={process.env.PUBLIC_URL + "/img/hospital.svg"}
                  alt="image"
                />
              </div>
            </div>

            <div className="col-margin col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <div className="square-price">
                <div className="box-square">
                  <div>
                    <p className="title-square">
                      DESCONTOS <br /> DENTISTAS
                    </p>
                  </div>
                  <div>
                    <p className="subtitle-square">Canal dentário</p>
                  </div>
                  <div>
                    <p className="price-annuncement-square mb-2">a partir de</p>
                  </div>
                  <div>
                    <div className="through mr-2">
                      <span className="type-price-2">R$</span>
                      <span className="price">300</span>
                      <span className="cents">,00</span>
                    </div>
                    <div>
                      <span className="type-price-2">R$</span>
                      <span className="price">170</span>
                      <span className="cents">,00</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-section">
                <img
                  className="img-rectangle"
                  src={process.env.PUBLIC_URL + "/img/rectangle-square.svg"}
                  alt="image"
                />
                <img
                  className="icon-rectangle"
                  src={process.env.PUBLIC_URL + "/img/tooth.svg"}
                  alt="image"
                />
              </div>
            </div>

            <div className="col-margin col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <div className="square-price">
                <div className="box-square">
                  <div>
                    <p className="title-square">DESCONTOS EM FARMÁCIA</p>
                  </div>
                  <div>
                    <p className="subtitle-square">Medicamentos</p>
                  </div>
                  <div>
                    <p className="price-annuncement-square">com até</p>
                  </div>
                  <div>
                    <span className="price">30%</span>
                  </div>
                </div>
              </div>
              <div className="rectangle-section">
                <img
                  className="img-rectangle"
                  src={process.env.PUBLIC_URL + "/img/rectangle-square.svg"}
                  alt="image"
                />
                <img
                  className="icon-rectangle"
                  src={process.env.PUBLIC_URL + "/img/medicament.svg"}
                  alt="image"
                />
              </div>
            </div>

            {_queryString.plan !== "br5facil" && (
              <>
                <div className="col-margin col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="square-price">
                    <div className="box-square">
                      <div>
                        <p className="title-square">
                          SEGURO <br /> DE VIDA
                        </p>
                      </div>
                      <div>
                        <p className="subtitle-square">Titular do plano</p>
                      </div>
                      <div>
                        <p className="price-annuncement-square">Valor total</p>
                      </div>
                      <div>
                        <span className="type-price-2">R$</span>
                        <span className="price">10.000</span>
                      </div>
                    </div>
                  </div>
                  <div className="rectangle-section">
                    <img
                      className="img-rectangle"
                      src={process.env.PUBLIC_URL + "/img/rectangle-square.svg"}
                      alt="image"
                    />
                    <img
                      className="icon-rectangle"
                      src={process.env.PUBLIC_URL + "/img/heart.svg"}
                      alt="image"
                    />
                  </div>
                </div>

                <div className="col-margin col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="square-price">
                    <div className="box-square">
                      <div>
                        <p className="title-square">
                          FUNERAL <br /> FAMILIAR
                        </p>
                      </div>
                      <div>
                        <p className="subtitle-square">
                          Em todo <br /> território
                        </p>
                      </div>
                      <div>
                        <p className="price-annuncement-square"></p>
                      </div>
                      <div>
                        <span className="price-nacional">NACIONAL</span>
                      </div>
                    </div>
                  </div>
                  <div className="rectangle-section">
                    <img
                      className="img-rectangle"
                      src={process.env.PUBLIC_URL + "/img/rectangle-square.svg"}
                      alt="image"
                    />
                    <img
                      className="icon-rectangle"
                      src={process.env.PUBLIC_URL + "/img/family.svg"}
                      alt="image"
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <h4 className="announcement-benefits mt-4">Principais parceiros:</h4>

          <div
            className="partners-section"
            style={{
              textAlign: "center",
              paddingTop: "2rem"
            }}
          >
            <img
              width={140}
              className="pt-3 pb-3"
              src={process.env.PUBLIC_URL + "/img/logo-seguro.svg"}
              alt="img-logo"
            />
            <img
              width={170}
              className="pt-3 pb-3"
              src={process.env.PUBLIC_URL + "/img/logo-drogasil.svg"}
              alt="img-logo"
            />
            <img
              width={140}
              className="pt-3 pb-3"
              src={process.env.PUBLIC_URL + "/img/logo-droga-raia.svg"}
              alt="img-logo"
            />
            <img
              width={160}
              className="pt-4 pb-3"
              src={process.env.PUBLIC_URL + "/img/logo-sulamerica.svg"}
              alt="img-logo"
            />
          </div>

          <div className="margin-0-auto col-12 col-sm-6 col-md-4 col-xl-3">
            <div
              onClick={() => closeModalOferta()}
              className="animate__animated animate__pulse animate__infinite contact100-form-btn-2"
            >
              <p className="use-credit">USAR CRÉDITO DE</p>
              <p className="price-use-credit">
                {formatNumber(plan?.discountInitialPayment)}
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={modalTermos}
        width="70%"
        height="80%"
        effect="fadeInUp"
        onClickAway={() => closeModalTermos()}
      >
        <div className="text-center termos-aceite">
          <h1 className="m-b-25">Termos de Aceite</h1>
          <span className="text-contrato">{contract}</span>
          <div className="container-contact100-form-btn m-t-20">
            <div className="wrap-contact100-form-btn">
              <div className="contact100-form-bgbtn"></div>
              <button
                onClick={() => closeModalTermos()}
                className="contact100-form-btn"
              >
                <span>
                  Fechar
                  <i
                    className="fa fa-long-arrow-right m-l-7"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={modalError}
        width="70%"
        height="80%"
        effect="fadeInUp"
        onClickAway={() => closeModalError()}
      >
        <div className="text-center termos-aceite">
          <FiInfo size={35} />
          <h1 className="m-b-30">Ops, encontramos alguns erros no cadastro</h1>
          <ul>
            {Object.keys(errors).length &&
              Object.keys(errors).map(item => (
                <li key={item} className="m-b-5">
                  <FiChevronRight /> {errors[item]}
                </li>
              ))}
          </ul>
          <div className="container-contact100-form-btn m-t-20">
            <div className="wrap-contact100-form-btn">
              <div className="contact100-form-bgbtn"></div>
              <button
                onClick={() => closeModalError()}
                className="contact100-form-btn"
              >
                <span>
                  Fechar
                  <i
                    className="fa fa-long-arrow-right m-l-7"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={modalBR5facil}
        width="70%"
        height="80%"
        effect="fadeInUp"
        onClickAway={() => closeModalBR5facil()}
      >
        <div className="text-center termos-aceite">
          {/* <FiInfo size={35} /> */}
          <p>
            Pensando em você que faz parte da melhor idade, criamos o produto
            exclusivo, o planoBR5 Fácil, com desconto em saúde, dentistas,
            farmácia e pets.
            <br />
            <br />
          </p>
          adesão única {formatNumber(plan.initialPayment)} +
          {formatNumber(plan.monthly)} /mês
          <div className="container-contact100-form-btn m-t-20">
            <div className="wrap-contact100-form-btn">
              <div className="contact100-form-bgbtn"></div>
              <button
                onClick={() => closeModalBR5facil()}
                className="contact100-form-btn"
              >
                <span>
                  Prosseguir com BR5 Fácil
                  <i
                    className="fa fa-long-arrow-right m-l-7"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={modalCellPhoneConfirm}
        effect="fadeInUp"
        onClickAway={() => () => {
          setModalCellPhoneConfirm(false);
          setLoading(false);
        }}
      >
        <div className="text-center body-modal">
          <button
            onClick={() => (!loading ? setModalCellPhoneConfirm(false) : "")}
            style={{
              position: "absolute",
              right: 0,
              top: 0
            }}
            className={
              loading ? "btn btn-secondary disabled" : "btn btn-secondary"
            }
          >
            <span>
              Fechar
              <i className="fas fa-times m-l-7"></i>
            </span>
          </button>
          <h4 className="title-offer mb-1">
            Precisamos que você confirme seu celular.
          </h4>
          <p className="text-white text-center">
            Enviamos um código de confirmação para você.
            <br />
            <br />
            <h6>
              <b>Por favor, informe-o abaixo:</b>
            </h6>
            <br />
          </p>

          <div className="col-sm-12 col-md-4 offset-md-4 text-center mb-4">
            <div className={"wrap-input100"}>
              <span className="label-input100 text-white">Insira o código</span>
              <input
                className="mb-2 input100"
                type="text"
                name="code"
                placeholder="Código"
                value={form.code}
                onChange={event => {
                  onPress("code", event.target.value, true);
                }}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 offset-md-3 text-center">
            <button
              onClick={() => (!loading ? submit() : "")}
              className={
                loading ? "btn btn-success disabled" : "btn btn-success w-75"
              }
            >
              <span>
                Assinar
                <i
                  className="fa fa-long-arrow-right m-l-7"
                  aria-hidden="true"
                ></i>
              </span>
            </button>
          </div>
        </div>
      </Modal>

      <div className="container-contact100">
        {loading && <RotateSpinner size={80} color="#5fd2e3" />}
        {!loading && (
          <div className="wrap-contact100">
            <span className="contact100-form-title p-b-3">Assine agora</span>

            <span className="contact100-form-sub-title p-b-0">
              e garanta a proteção da sua família por apenas
            </span>
            <div className="pr">
              <img
                className="image-price m-t-20 m-b-30"
                src={process.env.PUBLIC_URL + "/img/background-price.svg"}
                alt="image"
              ></img>
              {plan?.initialPayment === 0 && (
                <span className="pa preco-adesao">
                  {_queryString.campaing !== "oferta-especial" && (
                    <>adesão única {formatNumber(plan.initialPayment)} +</>
                  )}
                  {_queryString.campaing === "oferta-especial" && (
                    <>
                      adesão única{" "}
                      <del>{formatNumber(plan.initialPayment)}</del> +
                    </>
                  )}
                </span>
              )}
              {plan?.initialPayment > 0 &&
                _queryString.campaing !== "oferta-especial" && (
                  <span className="pa preco-adesao">
                    <>adesão única {formatNumber(plan.initialPayment)} +</>
                  </span>
                )}
              {plan?.monthly && (
                <span className="pa preco-mensalidade">
                  {formatNumber(plan.monthly)}
                </span>
              )}
              <span className="pa mes">por mês</span>
            </div>

            <span className="contact100-form-title p-b-3">
              1- Preencha seus dados pessoais
            </span>
            <form className="contact100-form validate-form p-t-45 m-b-20">
              <>
                <div style={{ display: "flex" }} className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className={"wrap-input100 validate-input m-r-10"}>
                      <span className="label-input100">Nome Completo</span>
                      {errors.name && (
                        <FiInfo
                          className="p-t-10"
                          size={20}
                          color={"#ec2323"}
                        />
                      )}
                      <input
                        className="input100"
                        autoFocus={true}
                        type="text"
                        name="name"
                        placeholder="Insira seu nome"
                        onBlur={() => verifyLead()}
                        value={form.name}
                        onChange={event => {
                          onPress("name", event.target.value, true);
                        }}
                      />
                      <span className="focus-input100"></span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className={"wrap-input100 validate-input m-r-10"}>
                      <span className="label-input100">
                        Celular
                        {errors.phone && (
                          <FiInfo
                            className="p-t-10"
                            size={20}
                            color={"#ec2323"}
                          />
                        )}
                      </span>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        className="input100"
                        type="text"
                        name="phone"
                        placeholder="Insira seu celular"
                        onBlur={() => verifyLead()}
                        value={form.phone}
                        onChange={event => {
                          onPress("phone", event.target.value, true);
                        }}
                      />
                      <span className="focus-input100"></span>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }} className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className={"wrap-input100 validate-input m-r-10"}>
                      <span className="label-input100">
                        Data de Nascimento
                        {errors.birth && (
                          <FiInfo
                            className="p-t-10"
                            size={20}
                            color={"#ec2323"}
                          />
                        )}
                      </span>
                      <InputMask
                        mask="99/99/9999"
                        className="input100"
                        type="text"
                        name="birth"
                        placeholder="Insira sua data de nascimento"
                        onBlur={() => verifyLead()}
                        value={form.birth}
                        onChange={event => {
                          onPress("birth", event.target.value, true);
                        }}
                      />
                      <span className="focus-input100"></span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className={"wrap-input100 validate-input m-r-10"}>
                      <span className="label-input100">
                        Documento (CPF)
                        {errors.document && (
                          <FiInfo
                            className="p-t-10"
                            size={20}
                            color={"#ec2323"}
                          />
                        )}
                      </span>
                      <InputMask
                        mask="999.999.999-99"
                        className="input100"
                        type="text"
                        name="document"
                        placeholder="Insira seu documento"
                        onBlur={() => verifyLead()}
                        value={form.document}
                        onChange={event => {
                          onPress("document", event.target.value, true);
                        }}
                      />
                      <span className="focus-input100"></span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className={"wrap-input100 validate-input m-r-10"}>
                      <span className="label-input100">
                        CEP
                        {errors.cep && (
                          <FiInfo
                            className="p-t-10"
                            size={20}
                            color={"#ec2323"}
                          />
                        )}
                      </span>
                      <InputMask
                        mask="99999-999"
                        className="input100"
                        type="text"
                        name="cep"
                        placeholder="Insira seu CEP"
                        onBlur={() => verifyLead()}
                        value={form.cep}
                        onChange={event => {
                          onPress("cep", event.target.value, true);
                        }}
                      />
                      <span className="focus-input100"></span>
                    </div>
                  </div>
                </div>
                <span className="contact100-form-title p-b-20">
                  2- Escolha a forma de pagamento
                </span>

                <div className="btn-group btn-group-toggle center-group-button m-t-10 group-discount">
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <div className="dis-flex">
                      <label
                        className={
                          "btn btn-forma-pagamento-up-active trans-0-5"
                        }
                      >
                        <span className="text-payment-up-active">
                          Até 10% de desconto
                        </span>
                      </label>
                      <label className={"btn btn-forma-pagamento-up trans-0-5"}>
                        <span className="text-payment-up-active"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="btn-group btn-group-toggle center-group-button">
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <label
                      onClick={() =>
                        onPress("paymentForm", "credit_card", true)
                      }
                      className={[
                        form.paymentForm === "credit_card"
                          ? "btn btn-forma-pagamento-active trans-0-5"
                          : "btn btn-forma-pagamento trans-0-5"
                      ]}
                    >
                      {/* <input type="radio" name="options" id="option1" />{" "} */}
                      <span className="text-payment">Cartão de Crédito</span>
                    </label>
                    <label
                      onClick={() => onPress("paymentForm", "billet", true)}
                      className={[
                        form.paymentForm === "billet"
                          ? "btn btn-forma-pagamento-active trans-0-5"
                          : "btn btn-forma-pagamento trans-0-5"
                      ]}
                    >
                      {/* <input type="radio" name="options" id="option2" />{" "} */}
                      <span className="text-payment">Boleto</span>
                    </label>
                  </div>
                </div>
                <div className="btn-group btn-group-toggle center-group-button group-discount">
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <div className="dis-flex ">
                      <label
                        className={
                          "btn btn-forma-mais-vendido-up-active trans-0-5"
                        }
                      >
                        <div className="animate__animated animate__pulse animate__delay-1s animate__infinite">
                          <span className="text-payment-up-active">
                            O mais vendido
                          </span>
                        </div>
                      </label>
                      <label className={"btn btn-forma-pagamento-up trans-0-5"}>
                        <span className="text-payment-up-active"></span>
                      </label>
                    </div>
                  </div>
                </div>
                {form.paymentForm && (
                  <>
                    <span className="description-pagamento contact100-form-sub-title p-b-0 p-t-30">
                      3- Escolha o período do pagamento
                    </span>
                    <div className="description-pagamento dis-flex m-t-20">
                      {form.paymentForm === "credit_card" && (
                        <div className="dis-flex">
                          <div
                            onClick={() => onPress("paymentType", "cc_anual")}
                            className="button-tipo m-r-20"
                          >
                            <div
                              className={
                                form.paymentType === "cc_anual"
                                  ? "text-description-pagamento-selected"
                                  : "text-description-pagamento"
                              }
                            >
                              <div
                                className={
                                  form.paymentType === "cc_anual"
                                    ? "circle-text-description-anual m-t-5"
                                    : "circle-text-description-mensal m-t-5"
                                }
                              ></div>
                              <span className="m-l-8">Anual</span>
                            </div>
                            <span
                              className={
                                form.paymentType === "cc_anual"
                                  ? "badge badge-pagamento-anual m-t-3"
                                  : "badge badge-pagamento-mensal m-t-3"
                              }
                              style={{ backgroundColor: "#dc3545" }}
                            >
                              10% desconto
                            </span>
                          </div>
                          <div
                            onClick={() => onPress("paymentType", "cc_mensal")}
                            className="button-tipo"
                          >
                            <div
                              className={
                                form.paymentType === "cc_mensal"
                                  ? "text-description-pagamento-selected"
                                  : "text-description-pagamento"
                              }
                            >
                              <div
                                className={
                                  form.paymentType === "cc_mensal"
                                    ? "circle-text-description-anual m-t-5"
                                    : "circle-text-description-mensal m-t-5"
                                }
                              ></div>
                              <span className="m-l-8">Mensal</span>
                            </div>
                            <span
                              className={
                                form.paymentType === "cc_mensal"
                                  ? "badge badge-pagamento-anual m-t-3"
                                  : "badge badge-pagamento-mensal m-t-3"
                              }
                              style={{ backgroundColor: "#dc3545" }}
                            >
                              5% desconto
                            </span>
                          </div>
                        </div>
                      )}
                      {/* <div className="separator-desc-pagamento"></div> */}
                      {form.paymentForm === "billet" && (
                        <div className="dis-flex">
                          <div
                            onClick={() =>
                              onPress("paymentType", "billet_anual")
                            }
                            className="button-tipo m-r-20"
                          >
                            <div
                              className={
                                form.paymentType === "billet_anual"
                                  ? "text-description-pagamento-selected"
                                  : "text-description-pagamento"
                              }
                            >
                              <div
                                className={
                                  form.paymentType === "billet_anual"
                                    ? "circle-text-description-anual m-t-5"
                                    : "circle-text-description-mensal m-t-5"
                                }
                              ></div>
                              <span className="m-l-8">Anual</span>
                            </div>
                            <span
                              className={
                                form.paymentType === "billet_anual"
                                  ? "badge badge-pagamento-anual m-t-3"
                                  : "badge badge-pagamento-mensal m-t-3"
                              }
                              style={{ backgroundColor: "#dc3545" }}
                            >
                              10% desconto
                            </span>
                          </div>
                          {plan.identifier !== "br5facil" && (
                            <div
                              onClick={() =>
                                onPress("paymentType", "billet_mensal")
                              }
                              className="button-tipo"
                            >
                              <div
                                className={
                                  form.paymentType === "billet_mensal"
                                    ? "text-description-pagamento-selected"
                                    : "text-description-pagamento"
                                }
                              >
                                <div
                                  className={
                                    form.paymentType === "billet_mensal"
                                      ? "circle-text-description-anual m-t-5"
                                      : "circle-text-description-mensal m-t-5"
                                  }
                                ></div>
                                <span className="m-l-8">Mensal</span>
                              </div>
                              <span
                                className={
                                  form.paymentType === "billet_mensal"
                                    ? "badge badge-pagamento-anual m-t-3"
                                    : "badge badge-pagamento-mensal m-t-3"
                                }
                              >
                                0% desconto
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {form.paymentType && form.paymentType === "cc_anual" && (
                  <div className="trans-0-5 text-center m-t-10 m-l-5">
                    <FiAlertCircle
                      color={"#ffc107"}
                      size={20}
                      className="m-t-10"
                    />
                    <span
                      style={{ fontSize: `${16}px`, lineHeight: `${1.3}em` }}
                    >
                      Anual: <del>{formatNumber(prices.total)}</del>
                      {formatNumber(prices.valAnnually)}
                      <span className={"badge badge-pagamento-green mt-0 ml-2"}>
                        -10%
                      </span>
                      <br></br>
                      <div className={"mt-1"}>
                        Cálculo: {formatNumber(prices.totalMonthly)} (total das
                        parcelas com 10% de desconto) +
                        {formatNumber(prices.totalInitialPayment)} (adesão
                        única)
                      </div>
                    </span>
                  </div>
                )}

                {form.paymentType && form.paymentType === "cc_mensal" && (
                  <div className="trans-0-5 text-center m-t-10 m-l-5">
                    <FiAlertCircle
                      color={"#ffc107"}
                      size={20}
                      className="m-t-10"
                    />
                    <span
                      style={{ fontSize: `${16}px`, lineHeight: `${1.3}em` }}
                    >
                      Mensal:
                      <del> {formatNumber(prices.valMonthly)}</del>{" "}
                      {formatNumber(prices.totalMonthly)}
                      <span className={"badge badge-pagamento-green ml-2"}>
                        -5%
                      </span>
                      <br></br>
                      {prices.totalInitialPayment >= 0 && (
                        <>
                          A primeira cobrança será no valor de
                          {formatNumber(prices.total)} <br />
                          (referente a soma de
                          {formatNumber(prices.totalInitialPayment)} da adesão
                          única)
                        </>
                      )}
                      {/* {prices.totalInitialPayment <= 0 && (
                        <>
                          <del>
                            A primeira cobrança será no valor de
                            {formatNumber(prices.total)} <br />
                            (referente a soma de
                            {formatNumber(prices.totalInitialPayment)} da adesão
                            única)
                          </del>
                        </>
                      )} */}
                    </span>
                  </div>
                )}

                {form.paymentType && form.paymentType === "billet_anual" && (
                  <div className="trans-0-5 text-center m-t-10 m-l-5">
                    <FiAlertCircle
                      color={"#ffc107"}
                      size={20}
                      className="m-t-10"
                    />
                    <span
                      style={{ fontSize: `${16}px`, lineHeight: `${1.3}em` }}
                    >
                      Anual: <del>{formatNumber(prices.valAnnually)}</del>
                      {formatNumber(prices.total)}
                      <span className={"badge badge-pagamento-green ml-2"}>
                        -10%
                      </span>
                      <br></br>
                      <div className={"mt-1"}>
                        Cálculo: {formatNumber(prices.totalMonthly)}
                        (total das parcelas com 10% de <br />
                        desconto) + {formatNumber(prices.totalInitialPayment)}
                        (adesão única)
                      </div>
                    </span>
                  </div>
                )}

                {form.paymentType && form.paymentType === "billet_mensal" && (
                  <div className="trans-0-5 text-center m-t-10 m-l-5">
                    <FiAlertCircle
                      color={"#ffc107"}
                      size={20}
                      className="m-t-10"
                    />
                    <span
                      style={{ fontSize: `${16}px`, lineHeight: `${1.3}em` }}
                    >
                      Mensal: {formatNumber(prices.totalMonthly)}
                      <br></br>
                      {prices.totalInitialPayment >= 0 && (
                        <>
                          A primeira cobrança será no valor de
                          {formatNumber(prices.total)} <br />
                          (referente a soma de
                          {formatNumber(prices.totalInitialPayment)} da adesão
                          única)
                        </>
                      )}
                      {/* {prices.totalInitialPayment <= 0 && (
                        <>
                          <del>
                            A primeira cobrança será no valor de
                            {formatNumber(prices.total)} <br />
                            (referente a soma de
                            {formatNumber(prices.totalInitialPayment)} da adesão
                            única)
                          </del>
                        </>
                      )} */}
                    </span>
                  </div>
                )}

                {form.paymentType &&
                  (form.paymentType === "cc_mensal" ||
                    form.paymentType === "cc_anual") && (
                    <div className="form-credit-card">
                      <div className="dis-flex row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className={"wrap-input100 validate-input m-r-10"}
                          >
                            <span className="label-input100">
                              Número do Cartão
                              {errors.card_number && (
                                <FiInfo
                                  className="p-t-10"
                                  size={20}
                                  color={"#ec2323"}
                                />
                              )}
                            </span>
                            <InputMask
                              mask="9999 9999 9999 9999"
                              className="input100"
                              type="text"
                              name="card_number"
                              placeholder="Insira o número do cartão"
                              value={form.card_number}
                              onChange={event => {
                                onPress("card_number", event.target.value);
                              }}
                            />
                            <span className="focus-input100"></span>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            className={"wrap-input100 validate-input m-r-10"}
                          >
                            <span className="label-input100">
                              Nome Impresso no Cartão
                              {errors.name_card && (
                                <FiInfo
                                  className="p-t-10"
                                  size={20}
                                  color={"#ec2323"}
                                />
                              )}
                            </span>
                            <input
                              className="input100"
                              type="text"
                              name="name_card"
                              placeholder="Insira o nome impresso no cartão"
                              value={form.name_card}
                              onChange={event => {
                                onPress("name_card", event.target.value);
                              }}
                            />
                            <span className="focus-input100"></span>
                          </div>
                        </div>
                      </div>
                      <div className="dis-flex row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className={"wrap-input100 validate-input m-r-10"}
                          >
                            <span className="label-input100">
                              Mês e Ano de Vencimento
                              {errors.monthYear && (
                                <FiInfo
                                  className="p-t-10"
                                  size={20}
                                  color={"#ec2323"}
                                />
                              )}
                            </span>
                            <InputMask
                              mask="99/99"
                              className="input100"
                              type="text"
                              name="card_month_year"
                              placeholder="Insira o mês e ano de vencimento"
                              value={form.monthYear}
                              onChange={event => {
                                onPress("monthYear", event.target.value);
                              }}
                            />
                            <span className="focus-input100"></span>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            className={"wrap-input100 validate-input m-r-10"}
                          >
                            <span className="label-input100">
                              Código de Segurança do Cartão
                              {errors.cvv && (
                                <FiInfo
                                  className="p-t-10"
                                  size={20}
                                  color={"#ec2323"}
                                />
                              )}
                            </span>
                            <InputMask
                              mask="9999"
                              maskChar=""
                              className="input100"
                              type="text"
                              name="cvv"
                              placeholder="Insira seu CVV"
                              value={form.cvv}
                              onChange={event => {
                                onPress("cvv", event.target.value);
                              }}
                            />
                            <span className="focus-input100"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {_queryString.campaing !== "oferta-especial" && (
                  <span className="contact100-form-sub-title p-b-0 p-t-30">
                    4- Escolha um dia de vencimento para as mensalidades
                  </span>
                )}
                {_queryString.campaing === "oferta-especial" && (
                  <span className="contact100-form-sub-title p-b-0 p-t-30">
                    A data de vencimento das suas mensalidades serão dia{" "}
                    <b>10</b>!
                  </span>
                )}
                <div className="m-t-30"></div>
                <div className="dis-flex group-vencimento">
                  {_queryString.campaing !== "oferta-especial" && (
                    <button
                      onClick={() => onPress("dueDate", 5)}
                      type="button"
                      className={[
                        form.dueDate === 5
                          ? "btn btn-vencimentos-active m-r-5 p-l-18 p-r-20"
                          : "btn btn-vencimentos m-r-5 p-l-18 p-r-20"
                      ]}
                    >
                      5
                    </button>
                  )}
                  <button
                    onClick={() => onPress("dueDate", 10)}
                    type="button"
                    className={[
                      form.dueDate === 10
                        ? "btn btn-vencimentos-active m-r-5 m-l-5"
                        : "btn btn-vencimentos m-r-5 m-l-5"
                    ]}
                  >
                    10
                  </button>
                  {_queryString.campaing !== "oferta-especial" && (
                    <button
                      onClick={() => onPress("dueDate", 20)}
                      type="button"
                      className={[
                        form.dueDate === 20
                          ? "btn btn-vencimentos-active m-l-5"
                          : "btn btn-vencimentos m-l-5"
                      ]}
                    >
                      20
                    </button>
                  )}
                </div>
              </>
            </form>
            <div className="m-t-50"></div>
            <div className="form-check text-center">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                checked={form.terms}
                onChange={() => onPress("terms", !form.terms)}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Eu li e concordo com os{" "}
                <a
                  href="#"
                  style={{ color: "#345EA8" }}
                  onClick={e => {
                    e.preventDefault();
                    openModalTermos();
                    getContract(plan.id, true, true);
                  }}
                >
                  termos de uso
                </a>
              </label>
            </div>
            {!blockUser && (
              <div className="container-contact100-form-btn">
                <div className="wrap-contact100-form-btn">
                  <div className="contact100-form-bgbtn"></div>
                  <button
                    onClick={() => (!loading ? cellPhoneConfirm() : "")}
                    className={
                      loading
                        ? "contact100-form-btn disabled"
                        : "contact100-form-btn"
                    }
                  >
                    <span>
                      Assinar
                      <i
                        className="fa fa-long-arrow-right m-l-7"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </button>
                </div>
              </div>
            )}
            {ref && (
              <span
                style={{
                  float: "right",
                  color: "#999999",
                  marginTop: `${20}px`
                }}
              >
                Ref: {ref}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AutoPurchase;
