require("dotenv").config();

// const production = true;
// let base;

const config = {};
// config.production = process.env.REACT_APP_NODE_ENV === "production";
config.production = true;
// config.base = "http://localhost:1002/api/v1/";
config.base = "https://checkoutapi.br5beneficios.com.br/api/v1/";

// if (production) {
//   base = "http://checkout.api.br5beneficios.com.br/api/v1/";
// } else {
//   // base = "http://checkout.api.br5beneficios.com.br/api/v1/";
//   // base = "http://br5-checkout-production.sa-east-1.elasticbeanstalk.com/api/v1";
// }

module.exports = config;
